import React,{Component} from 'react';
import {BlogDetailsWrapper,BlogContent,BlogTextLayout,BlogDate,CountContainer,
    UserImage,UserText,CommentImage,CommentText,BlogdetailsImageHolder,
    BlogLeftImageHolder,BlogFeatureLayout,BlogIcon,SocialContainer,Social,FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,
    AuthorLayout,AuthorImageHolder,AuthorTextLayout,BlogImgTxtCol,BlogRightTxtCol,FormWrapper,
    Divider,BlogHeadingWrapper,BlogHeadingRightCol,BlogHeadingLeftCol,AuthorSocialContainer,
    Form,FormHeading,FormLayout,FormGroup,InputText,ContactSpanErr,TextareaMessage,TextCenter,SubmitBtn,
    ContactSpanSuccess
} from './blogdetails.style';
import {Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const WRNG_MSG_TIMEOUT = 3000;


class BlogDetailsSection extends Component{

    constructor(props) {
        super(props);
        this.state = {
            contact_name:"",
            contact_email:"",
            contact_phone:"",
            contact_message:"",
            success_contact_message:"",
            errors:[]
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(this.state.contact_name === "")
        {
            error_flag = true;
            errors['contact_name'] = "Please enter full name!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email === "")
        {
            error_flag = true;
            errors['contact_email'] = "Please enter email!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email !== "")
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.contact_email)) {
                error_flag = true;
                errors["contact_email"] = "Please enter valid email ID!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contact_phone === "")
        {
            error_flag = true;
            errors["contact_phone"] = "Please enter Mobile No!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }
        if(this.state.contact_phone !== "")
        {
            var patternPhone = new RegExp(/^\d+$/);
            if(!patternPhone.test(this.state.contact_phone)) {
                error_flag = true;
                errors["contact_phone"] = "Please enter valid Mobile No!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
            if(this.state.contact_phone.length<10) {
                error_flag = true;
                errors["contact_phone"] = "Please enter valid Mobile No!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contact_message === "")
        {
            error_flag = true;
            errors["contact_message"] = "Please enter message!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    contactUsSubmit = (e) =>{
        e.preventDefault();
        if(!this.validateForm())
        {
            //SUCCESS
            //TO DO
            console.log("Name: "+this.state.contact_name);
            console.log("Email: "+this.state.contact_email);
            console.log("Phone: "+this.state.contact_phone);
            console.log("Message: "+this.state.contact_message);
            this.setState({
                contact_name:"",
                contact_email:"",
                contact_phone:"",
                contact_message:"",
                success_contact_message:"Thanks for contacting us.",
                errors:[]
            },()=>{
                setTimeout(() => {
                    this.setState({
                        success_contact_message:""
                    });
               },WRNG_MSG_TIMEOUT);
            });
        }
    }

  render() {
    return (
        <BlogDetailsWrapper>
            <BlogContent>
                <BlogTextLayout>
                    <BlogHeadingWrapper>
                        <BlogHeadingLeftCol>
                            <BlogDate>
                                <h3>{this.props.BlogData.BlogDate}</h3>
                                <h5>{this.props.BlogData.BlogMonth}</h5>
                            </BlogDate>
                        </BlogHeadingLeftCol>

                        <BlogHeadingRightCol>
                            <h3>
                                {this.props.BlogData.BlogHeading}
                            </h3>
                            <CountContainer>
                                <span>
                                    <UserImage src={this.props.BlogData.UserImage}  />
                                    <UserText>{this.props.BlogData.UserText}</UserText>
                                </span>

                                <span>
                                    <CommentImage src={this.props.BlogData.CommentImage} />
                                    <CommentText>{this.props.BlogData.CommentText}</CommentText>
                                </span>
                            </CountContainer>
                        </BlogHeadingRightCol>
                    </BlogHeadingWrapper>

                    <BlogdetailsImageHolder>
                        <GatsImg className="blogdetailsimg"
                            fluid={this.props.BlogData.BlogImage.childImageSharp.fluid}
                            alt=""
                        />
                    </BlogdetailsImageHolder>
                    <p>
                    {this.props.BlogData.BlogText1}
                    </p>
                    <BlogImgTxtCol>
                      <BlogLeftImageHolder>
                            <GatsImg
                                fluid={this.props.BlogData.BlogText2Image.childImageSharp.fluid}
                                alt=""
                            />
                      </BlogLeftImageHolder>
                      <BlogRightTxtCol>
                            <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages

                            </p>

                          <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages</p>
                      </BlogRightTxtCol>
                    </BlogImgTxtCol>
                    <p>
                    {this.props.BlogData.BlogText2}
                    </p>
                    <p>
                    {this.props.BlogData.BlogText3}
                    </p>
                    <BlogFeatureLayout>
                      <ul>
                        <li>
                          <BlogIcon src={this.props.BlogData.BlogText4Icon}/>
                          <p>
                          {this.props.BlogData.BlogText4[0].Text}
                          </p>
                        </li>
                        <li>
                          <BlogIcon src={this.props.BlogData.BlogText4Icon}/>
                          <p>
                          {this.props.BlogData.BlogText4[1].Text}
                          </p>
                        </li>
                        <li>
                          <BlogIcon src={this.props.BlogData.BlogText4Icon}/>
                          <p>
                          {this.props.BlogData.BlogText4[2].Text}
                          </p>
                        </li>
                        <li>
                        </li>
                        <li>
                        <BlogIcon src={this.props.BlogData.BlogText4Icon}/>
                        <p>
                        {this.props.BlogData.BlogText4[3].Text}
                        </p>
                        </li>
                      </ul>

                    </BlogFeatureLayout>
                    <SocialContainer>
                        <Social href="/" aria-label="Facebook Link">
                            <FbIcon/>
                        </Social>
                        <Social href="/" aria-label="Twitter Link">
                            <TwitterIcon/>
                        </Social>
                        <Social href="/" aria-label="Linkedin Link">
                            <LinkedinIcon/>
                        </Social>
                        <Social href="/" aria-label="Instagram Link">
                            <InstaIcon/>
                        </Social>
                    </SocialContainer>
                </BlogTextLayout>
            </BlogContent>
            <Divider/>
            <AuthorLayout>
                <AuthorImageHolder>
                    <GatsImg
                        fluid={this.props.BlogData.AuthorImage.childImageSharp.fluid}
                        alt=""
                    />
                </AuthorImageHolder>
                <AuthorTextLayout>
                    <h4>
                    {this.props.BlogData.AuthorName}
                    </h4>
                    <p>
                    {this.props.BlogData.AuthorDesc}
                    </p>
                    <AuthorSocialContainer>
                        <a className="auth-fb"  href="/" aria-label="Facebook Link">
                            <FbIcon/>
                        </a>
                        <a className="auth-twt" href="/" aria-label="Twitter Link">
                            <TwitterIcon/>
                        </a>
                        <a className="auth-linked" href="/" aria-label="Linkedin Link">
                            <LinkedinIcon/>
                        </a>
                        <a className="auth-insta" href="/" aria-label="Instagram Link">
                            <InstaIcon/>
                        </a>
                    </AuthorSocialContainer>
                </AuthorTextLayout>
            </AuthorLayout>
            <FormLayout>
                <Form method="post">
                <FormHeading>{this.props.BlogData.FormHeading}</FormHeading>
                   <FormWrapper>
                      <Row>
                          <Col md="6">
                              <FormGroup>
                                  <InputText aria-label="Name" type="text" placeholder="Name"  name="contact_name" onChange={this.handleChange} value={this.state.contact_name}/>
                                  <ContactSpanErr>{this.state.errors.contact_name}</ContactSpanErr>
                              </FormGroup>
                          </Col>
                          <Col md="6">
                              <FormGroup>
                                  <InputText aria-label="Phone" type="tel" placeholder="Phone"  name="contact_phone" onChange={this.handleChange} value={this.state.contact_phone}/>
                                  <ContactSpanErr>{this.state.errors.contact_phone}</ContactSpanErr>
                              </FormGroup>
                          </Col>
                      </Row>
                      <Row>
                          <Col md="12">
                              <FormGroup>
                                  <InputText aria-label="Email" type="email" placeholder="Email"  name="contact_email" onChange={this.handleChange} value={this.state.contact_email}/>
                                  <ContactSpanErr>{this.state.errors.contact_email}</ContactSpanErr>
                              </FormGroup>
                          </Col>
                          <Col md="12">
                              <FormGroup>
                                  <TextareaMessage aria-label="Comments" type="text" placeholder="Comments" name="contact_message" onChange={this.handleChange} value={this.state.contact_message}></TextareaMessage>
                                  <ContactSpanErr>{this.state.errors.contact_message}</ContactSpanErr>
                              </FormGroup>
                          </Col>
                          <Col md="12">
                              <TextCenter>
                                  <SubmitBtn href="/" onClick={this.contactUsSubmit}>Submit</SubmitBtn>
                              </TextCenter>
                              <ContactSpanSuccess>{this.state.success_contact_message}</ContactSpanSuccess>
                          </Col>
                      </Row>
                    </FormWrapper>
                </Form>
            </FormLayout>
        </BlogDetailsWrapper>
        );
    }
}

const BlogDetailSection = () => (
    <StaticQuery
        query={graphql`
            query {
                blogPage1Json{
                    BlogDetails{
                        BlogImage{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        BlogDate
                        BlogMonth
                        BlogHeading
                        UserImage
                        UserText
                        CommentImage
                        CommentText
                        BlogText1
                        BlogText2Image{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        BlogText2
                        BlogText3
                        BlogText4Icon
                        BlogText4{
                            Text
                        }
                        AuthorImage{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        AuthorName
                        AuthorDesc
                        FormHeading
                    }
                }
            }
        `}
        render={(data) => (
            <BlogDetailsSection BlogData={data.blogPage1Json.BlogDetails}/>
        )}
    />
  )
  export default BlogDetailSection;
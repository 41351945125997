import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Footer from "../components/Footer";
import BlogDetailsPage from "../containers/BlogPage1/BlogDetailsPage";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogDetails = () => (
    <Layout>
        <SEO
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS forceTopSticky = {"true"}/>
        
        <BlogDetailsPage />
        <Footer />
    </Layout>
        
)

export default BlogDetails;

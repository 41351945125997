import styled from 'styled-components';
import {Commonbtn} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const BlogDetailsWrapper = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }
`;

export const BlogHeadingWrapper = styled.div`
    display: flex;
    margin-bottom:20px;

    @media ${device.mobileL} {
        flex-direction:column;
    }

`;

export const BlogHeadingRightCol = styled.div`
    margin-left: 15px;

    h3{
        margin-bottom:5px;
    }

    @media ${device.mobileL} {
        margin-left:0px;
    }
`;
export const BlogHeadingLeftCol = styled.div`
    min-width: 100px;
    flex-shrink: 0;
    background: #ff3c1c;
    height: fit-content;
    text-align: center;
    padding: 10px;

    @media ${device.tablet} {
        width:100px;
        margin-bottom:20px;
    }
`;


export const BlogContent = styled.div`
    margin-bottom:40px;
`;

export const BlogdetailsImageHolder = styled.div`
    margin-bottom:20px;
    width:100%;
    height:300px;
    .blogdetailsimg{
        height:300px;
    }
`;
export const BlogTextLayout = styled.div`

`;
export const BlogDate = styled.div`
    h3{
        color:#fff;
        margin:0px;
        line-height:1;
    }
    h5{
        color:#fff;
        margin:0px;
        line-height:1;
    }
`;

export const CountContainer = styled.div`
    display:flex;
    align-items:center;

    span{
        display:flex;
        align-items:center;
        margin-right:15px;
    }
    span p{
        margin-bottom:0px;
        line-height:1;
    }
    span img{
        height: 18px;
        margin-right:5px;
    }
`;

export const UserImage = styled.img`

`;

export const UserText = styled.p`

`;


export const CommentImage = styled.img`

`;

export const CommentText = styled.p`

`;

export const BlogLeftImageHolder = styled.div`
    width:300px;
    flex-shrink:0;
    border-radius:4px;

    @media ${device.tablet} {
        width:100%;
        margin-bottom:20px;
    }
`;


export const BlogFeatureLayout = styled.div`
    ul{
        margin:0px;
        padding:0px;
        margin-bottom:30px;
    }
    ul li{
        display:flex;
        align-items:flex-start;
    }
`;


export const BlogImgTxtCol = styled.div`
    display:flex;
    align-items:Center;
    margin-bottom:30px;

    @media ${device.tablet} {
        flex-direction:column;
        align-items:flex-start;
        margin-bottom:0px;
    }
`;

export const BlogRightTxtCol = styled.div`
    margin-left:15px;

    @media ${device.tablet} {
        margin-left:0px;
    }
`;


export const BlogIcon = styled.img`
    width:12px;
    margin-top:7px;
    margin-right:5px;
`;

export const SocialContainer = styled.div`
    text-align:left;
    @media ${device.tablet} {
        text-align:center;
    }
`;

export const Social = styled.a`
    margin-right:5px;
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #ec008c;
    transition:all .5s;
    :hover{
        background: none;
        color: #ec008c;
        border:2px solid #ec008c;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #2e318f;
    transition:all .5s;
    :hover{
        background: none;
        color: #2e318f;
        border:2px solid #2e318f;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #00aeee;
    transition:all .5s;
    :hover{
        background: none;
        color: #00aeee;
        border:2px solid #00aeee;
    }
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #3949aa;
    transition:all .5s;
    :hover{
        background: none;
        color: #3949aa;
        border:2px solid #3949aa;
    }
`;

export const AuthorLayout = styled.div`
    display:flex;
    align-items:flex-start;
    padding:40px 0px;

    @media ${device.mobileM} {
        flex-direction:column
    }
`;
export const AuthorImageHolder = styled.div`
    width:80px;
    flex-shrink:0;

    img{
        border-radius:100%;
    }
`;

export const AuthorSocialContainer = styled.div`
    a svg{
        background:none;
        padding:0px;
        border-radius:0px;
        margin: 0px 0px;
        margin-right:8px;
        width:22px;
    }
    a:hover svg{
        border:none;
        transform:scale(1.1);
    }
    .auth-fb svg{
        color: #3949aa;
    }
    .auth-twt svg{
        color: #00aeee;
    }
    .auth-linked svg{
        color: #2e318f;
    }
    .auth-insta svg{
        color: #ec008c;
    }
`;
export const AuthorTextLayout = styled.div`
    margin-left:20px;

    @media ${device.mobileM} {
        margin-left:0px;
        margin-top:20px;
    }

    h4{
        text-align:left;
        font-size:24px;
        margin-bottom:10px;
    }

    p{
        text-align:left;
        margin-bottom:10px;
    }
`;

export const Divider = styled.hr`
    margin:0px 0px;
`;

export const FormLayout = styled.div`

`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormHeading = styled.h4`

`;

export const FormGroup = styled.div`
    margin-bottom:30px;
`;

export const InputText = styled.input`
    width: 100%;
    border: none;
    border:1px solid #ccc;
    padding: 8px;
    border-radius:4px;
    outline:0;
    font-size: 16px;
`;

export const ContactSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextareaMessage = styled.textarea`
    width: 100%;
    border: none;
    border:1px solid #ccc;
    padding: 8px;
    border-radius:4px;
    height:100px;
    outline:0;
    font-size: 16px;
`;

export const TextCenter = styled.div`
    text-align:left;
`;

export const SubmitBtn = styled(Commonbtn)`
    font-weight:bold;
    padding:8px 35px;

    :hover{
        color:#ff3c1c;
        text-decoration:none;
    }
`;

export const ContactSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const FormWrapper = styled.div`
    background:#fafafa;
    padding:30px;
    border-radius:4px;
`;
